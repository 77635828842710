<div>
    <div class="max-width">
        <div class="search-section full-centered" >
            <div class="row full-width">
                <div class="col-7 full-centered">
                    <div class="row">
                        <div class="col-12 full-centered">
                            <h1 class="white-color">
                                ES LO QUE NECESITAS 
                            </h1>
                        </div>
                        <div class="col-12 full-centered">
                            <h2 class="white-color">
                                ¡Te ayudamos a controlar tu consumo de agua y gas con nuestros medidores!
                            </h2>
                        </div>
                        <div class="col-12 full-centered pa-t-20">
                            <a class="app-img app-img-left  max-btn-animate"  
                                href="https://apps.apple.com/mx/app/gawi/id1620592179?l=en"  target="_blank">
                                <img src="/assets/images/components/footer/app_store.png">
                            </a>
                        </div>
                        <div class="col-12 full-centered">
                            <a class="app-img app-img-left  max-btn-animate"  
                                href="https://play.google.com/store/apps/details?id=com.imtech.gawi.gas"  target="_blank">
                                <img src="/assets/images/components/footer/play-store.png">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-5 full-left pa-t-20">
                    <img class="home-init-phone" src="/assets/images/pages/home/home-init-phone.png">
                </div>
            </div>
        </div>
    </div>
    <div class="row info-alexa pa-t-20 pa-b-20">
        <div class="col-4 full-centered">
            <img src="/assets/images/components/footer/amazon-alexa.png">
        </div>
        <div class="col-8 full-centered">
            <h2 class="">
                "Alexa, mis medidores" y obten la lista de tus meidores actuales.
            </h2>
        </div>
    </div>
    <div class="row info-sec pa-t-10 pa-b-20">
        <div class="col-12 full-centered">
            <h1 class="">
                ¿Qué es gawi?
            </h1>
        </div>
        <div class="col-12 full-centered">
            <p class="full-centered">
               Gawi es un sistema de gestion de agua y gas con el cual podras revisar el gasto de tu red o tanque de gas estacionario,
               recibir alertas de fugas, vacios, goteos, consumo diario y hasta configurar tu alexa para preguntarle cuanto
               vas a pagar cada mes.
            </p>
        </div>
    </div>
    <div class="max-width">
        <div class="black-section" >
            <div class="row full-width">
                <div class="col-12 full-centered">
                    <h1 class="white-color pa-t-20 pa-b-20">
                        ¿Por qué usar Gawi?
                    </h1>
                </div>
                <div class="col-4 full-centered pa-20">
                    <div class="row">
                        <div class="col-12 full-centered">
                            <i class="fas fa-coins white-color "></i>
                        </div>
                        <div class="col-12 full-centered">
                            <h2 class="white-color">
                                Controle fácilmente sú consumo de agua y gas
                            </h2>
                        </div>
                        <div class="col-12 full-centered">
                            <label class="white-color">
                                Esté al tanto en todo el tiempo. 
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-4 full-centered pa-20">
                    <div class="row">
                        <div class="col-12 full-centered">
                            <i class="fas fa-file-chart-pie"></i>
                        </div>
                        <div class="col-12 full-centered">
                            <h2 class="white-color pa-t-20">
                                Observe cuidadosamente sus hábitos de consumo
                            </h2>
                        </div>
                        <div class="col-12 full-centered">
                            <label class="white-color">
                                Vea detalladamente el historial de consumo de cada insumo. 
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-4 full-centered pa-20">
                    <div class="row">
                        <div class="col-12 full-centered">
                            <i class="fas fa-bells white-color"></i>
                        </div>
                        <div class="col-12 full-centered">
                            <h2 class="white-color pa-t-20">
                                Nunca mas se quede sin producto
                            </h2>
                        </div>
                        <div class="col-12 full-centered">
                            <label class="white-color">
                                Alertas de recarga, fugas, mal funcionamiento de la red... 
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row info-sec-how-works pa-t-20 pa-b-20">
        <div class="col-12 full-centered">
            <h1 class="">
                ¿Cómo funciona <strong>Gawi</strong>?
            </h1>
        </div>
        <div class="col-6 full-centered full-right">
            <img class="download-phone" src="/assets/images/pages/home/download-phone.png">
        </div>
        <div class="col-6 full-centered full-left">
            <div class="row">
                <div class="col-12">
                    <h1>
                        <strong>Descarga</strong>
                    </h1>
                </div>
                <div class="col-12">
                    <p class="full-centered left-col">
                        Ingresa a la tienda de aplicaciones de tu smartphone (App Store o Google Play), busca la app Gawi y descarga.
                    </p>
                </div>
            </div>
        </div>
        
        <div class="col-6 full-centered full-right">
            <div class="row">
                <div class="col-12">
                    <h1 class="right-col">
                        <strong>Agrega</strong>
                    </h1>
                </div>
                <div class="col-12">
                    <p class="full-centered right-col">
                        Registra uno de nuestros medidores, ya sea un medidor de agua o gas..
                    </p>
                </div>
            </div>
        </div>
        <div class="col-6 full-centered full-left">
            <img class="download-phone" src="/assets/images/pages/home/add-phone.png">
        </div>


        <div class="col-6 full-centered full-right">
            <img class="download-phone" src="/assets/images/pages/home/monit-phone.png">
        </div>
        <div class="col-6 full-centered full-left">
            <div class="row">
                <div class="col-12">
                    <h1>
                        <strong>Monitorea</strong>
                    </h1>
                </div>
                <div class="col-12">
                    <p class="full-centered left-col">
                        Selecciona tu medidor para ver tu registro de consumo y los ajustes.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="max-width">
        <div class="blue-section" >
            <div class="row full-width">

                <div class="col-8 full-right pa-30">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="white-color pa-t-20 pa-b-10">
                                Tecnología al alcance de todos
                            </h1>
                        </div>
                        <div class="col-12">
                            <label class="white-color pa-b-10">
                                Disponible en todo el territorio mexicano
                            </label>
                            <p class="white-color" >
                                Tu mejor solución para un control óptimo de tu consumo de agua y gas.
                                Evoluciona a la nueva generación de medidores domésticos.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-4 full-centered pa-20">
                    <img class="icon-app" src="/assets/images/pages/home/icon-app.jpeg">
                </div>
            </div>
        </div>
    </div>
</div>