import { Injectable } from '@angular/core';
import { deployConf } from './../../utils/config';
import { HttpClient, HttpHeaders, HttpEvent, HttpRequest, HttpParams } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { timeout, catchError } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { ServerMessage } from '../../classes/serverMessage.class';
import { User } from '../../classes/user.class';
import { ApnDto } from '../../classes/apn.class';
import { Organization } from '../../classes/organization.class';
import { FieldDevices } from 'src/app/classes/fieldDevices.class';
import { async } from '@angular/core/testing';



@Injectable({
  providedIn: 'root'
})
export class ApiDataService {
  baseURL: string = deployConf.apiUrl;
  token: String;

  //52 endpoints web (8 meses) + 67 end points movil (8 meses) + 7 rutas para medidores (5 meses)  = 119 end points totales
  //La primera que se realizaron los envios de informacion y logue se termino en 3 semanas (1 mes a lo mucho)
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  setToken(newToken: String) {
    this.token = newToken;
  }

  getImage(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<any>(url, { headers: headers, responseType: 'blob' as 'json' })
        .pipe(
          timeout(2000),
          catchError(e => {
            // do something on a timeout
            //reject(e);
            return of(null);
          })
        ).subscribe((imageBlob) => {
          let objectURL = "";
          if (imageBlob != null && imageBlob != undefined) {
            objectURL = URL.createObjectURL(imageBlob);
          }
          resolve(this.sanitizer.bypassSecurityTrustUrl(objectURL));
        }, (error: ServerMessage) => {
          reject(error)
        });
    })
  }

  async uploadImageBrand(formData: FormData): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'uploads/brand-image/', formData, { headers: headers })
        .subscribe((res: ServerMessage) => {
          if (res.error == false) {
            resolve(res);
          } else if (res.error == undefined) {
            reject(res);
          } else {
            resolve(res);
          }
        }, (error) => {
          reject(error);
        });
    });
  }

  //USER END-POINTS
  doLogin(email: String, password: String): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const data = { email: email, password: password };

      this.http.post<ServerMessage>(this.baseURL + 'auth/login', data, {}).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }


  doResetPassword(recoverEmail: string): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      this.http.post<ServerMessage>(
        this.baseURL + 'public/reset-password', { recoverEmail: recoverEmail }, {}).subscribe((response: ServerMessage) => {
          resolve(response);
        }, (error: any) => {
          reject(error);
        });
    });
  }


  getUserData(token: String): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })

      this.http.get<ServerMessage>(this.baseURL + 'auth/validate-token', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async updateUserAdminData(newUserData: User): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/update-user-data', newUserData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async createNewProfileUser(newUserData: {
    fullName: string,
    telephone: string,
    userName: string,
    email: string,
    password: string,
    confirmPass: string,
    profileType: string,
    noAgent: number,
    acceptTerms: string,
    recaptcha: string
  }): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'user/create-user-profile', newUserData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }


  //ADMINISTRATOR END-POINTS

  //Admin Organization
  getOrganizationAdminData(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/get-organization-settings-data', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
       
      }, (error) => {
        reject(error)
      });
    })
  }
  //  -All devices
  getAllDevicesData(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-all-devices-list', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  

  getAllOrganizationRegions(idOrganization: number): Promise<ServerMessage> {
    
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
      this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/find-regions/' + idOrganization, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  // Get all zones
  getAllOrganizationZones(idOrganization: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
      this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/find-zones-organization/' +idOrganization, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  
  // Get all stations
  getAllOrganizationStations(idOrganization: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
      this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/find-stations-byOrganization/' + idOrganization, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  } 





  // Get all regions
  getAllRegions(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-all-regions', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  // Get all zones
  getAllZones(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-all-zones', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  
  // Get all stations
  getAllStations(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-all-stations', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }  

  //Individual device water history
  getAllHistoryNaturalGasDeviceData(queryData: {
    idDevice: number,
    fromDate: Date,
    toDate: Date,
    period: number,
    serialNumbersExtraDevices: string[]
  }): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/get-all-history-natural-gas-device', queryData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  //Individual device water history
  getAllHistoryWaterDeviceData(queryData: {
    idDevice: number,
    fromDate: Date,
    toDate: Date,
    period: number,
    serialNumbersExtraDevices: string[]
  }): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/get-all-history-water-device', queryData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  updateApnDeviceData( updatedData : {
    idDevice: number,
    idApn: number,
  }): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/update-device-apn', updatedData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  unlockDeviceToBeAssigned( updatedData : {
    idDevice: number,
  }): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/unlock-assigned-device', updatedData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  //Individual device logger history
  getAllHistoryLoggerDeviceData(queryData: {
    idDevice: number,
    fromDate: Date,
    toDate: Date,
  }): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/get-all-history-logger-device', queryData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  //Individual device gas history for administrator
  getAllHistoryGasDeviceData(queryData: {
    idDevice: number,
    fromDate: Date,
    toDate: Date,
    period: number,
  }): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/get-all-history-gas-device', queryData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  
  ////Admin My Organization : 

  //Root admin data
  getHomeAdminData(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-home-admin', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  //  -All organization devices
  getAllOrganizationDevicesData(idOrganization: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-all-organization-devices-list/' + idOrganization, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }


 //  -All organization devices
 getAllOrganizationDevices(idOrganization: number): Promise<ServerMessage> {
  return new Promise((resolve, reject) => {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })

    this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/find-devices-byOrganization/' + idOrganization, { headers: headers }).subscribe((response: ServerMessage) => {
      
      resolve(response);
    }, (error) => {
      reject(error)
    });
  })
}

  //  -Users Technician
  getOrganizationTechnicianAdminData(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-technician-users-data', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  //  -Users Counters
  getOrganizationCountersAdminData(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-counters-users-data', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  //  -Users Warehouses
  getOrganizationWarehousesAdminData(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-warehouse-users-data', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  //  -Users Clients
  getOrganizationClientsAdminData(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-clients-users-data', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  getOrganizationClientProfileAdminData(idUser: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-client-profile-data/' + idUser, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  //  -All platform users
  getAllAccountUsersData(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-all-account-users-data', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  //  -All Users CRUD for Admin
  async createNewUserAdmin(newUserData: User): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/create-user', newUserData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async updateUserAdmin(newUserData: User): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/update-user', newUserData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async deleteUserAdmin(idUser: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'administrator/delete-user/' + idUser, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  ////WAREHOUSE : 
  //  -Home page data load
  async loadHomeDataWarehouse(): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'wharehouse-devices-admin/get-home-data', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  //  -Water Devices 
  async getApnCatalogList(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'wharehouse-devices-admin/get-apn-list', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async createNewDeviceWarehouse(newUserData: { imei: string, serialNumber: string, type: number , idApn : number, }): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'wharehouse-devices-admin/create-device', newUserData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  async createNewDevicesByArrayWarehouse(newDevicesData: { imei: string, serialNumber: string, type: number , idApn : number,}[]): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'wharehouse-devices-admin/create-multiple-devices', newDevicesData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  
  // ADMIN ASSIGNMENTS 
  async manualAssignment( data : [{
    serialNumber: string,
    type: number,
    idOrganization: number,
  }] ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url: string = this.baseURL + 'administrator/manual-assignments';
      const headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(url, data ,{ headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  // ADMIN PAYMENTS 
  async manualSubscriptionsActivations( data : [{
    serialNumber: string,
    type: number,
    years: number,
  }] ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url: string = this.baseURL + 'administrator/payments/manual-subscriptions-activations';
      const headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(url, data ,{ headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  async getAdminSubscriptionPayments( queryParams : any ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url: string = this.baseURL + 'administrator/payments/get-subscription-payment-list';
      const headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(url, queryParams ,{ headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }
  async doSubscriptionBill( data: { idHistoryPayment: number } ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url: string = this.baseURL + 'administrator/payments/create-invoice';
      const headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(url, data ,{ headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  async getAdminBills( queryParams : any ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url: string = this.baseURL + 'administrator/payments/get-already-bills-list';
      const headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(url, queryParams ,{ headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  // ADMIN SETTINGS
  async getAdminSettingViewData(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url: string = this.baseURL + 'administrator/admin-data/get-admin-settings-data';
      const headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.get<ServerMessage>(url, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  async updateAdminProfileData(adminUserData: any): Promise<ServerMessage> {
    return new Promise(async (resolve, reject) => {
      const url: string = this.baseURL + 'administrator/admin-data/update-admin-profile-data';
      const headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(url, adminUserData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  //Verify device data
  async checkAlreadyDevice(newUserData: { serialNumber: string, type: number }): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'wharehouse-devices-admin/check-already-device', newUserData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  /* APN END POINTS */
  async getApnList(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'administrator/admin-data/get-apn-list', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  
  async createNewAPNAdmin(newApnData: ApnDto): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/admin-data/create-new-apn', newApnData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async updateAPNAdmin(apnData: ApnDto): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/admin-data/update-apn', apnData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async deleteAPNAdmin(apnDataToDelete: ApnDto, apnDataToSet: ApnDto ): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/admin-data/delete-apn', {
        apnDataToDelete : apnDataToDelete,
        apnDataToSet : apnDataToSet,
      }, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  // get gas alerts
  getGasDeviceAlerts(
    idDevice: number,
    period: number
  ): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {  
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(
        this.baseURL + 'administrator/get-gas-device-alerts/' + idDevice + '/' + period,
        { headers: headers }).subscribe((response: ServerMessage) => {
          resolve(response);
        }, (error) => {
          reject(error)
        });
    })
  }

  // get water alerts
  getWaterDeviceAlerts(
    idDevice: number,
    period: number
  ): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {  
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(
        this.baseURL + 'administrator/get-water-device-alerts/' + idDevice + '/' + period,
        { headers: headers }).subscribe((response: ServerMessage) => {
          resolve(response);
        }, (error) => {
          reject(error)
        });
    })
  }

  /**
   * 
   */
  getOrganizationsData(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-organizations-data', {
        headers: headers
      }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  /**
   * 
   */
  getOrganizationsListData(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-organizations-list-data', {
        headers: headers
      }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  /**
   * @TODO cambiar al controlador de admin/organization
   */
   async updateOrganizationData(organizationData: FormData): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        // 'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(
        this.baseURL + 'administrator/update-organization-data', organizationData, {
          headers: headers
        }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }


 /**
  * @TODO cambiar al controlador de admin/organization
  */
  async getOrganizationAdminChoices(choice: string): Promise<ServerMessage> {
   return new Promise((resolve, reject) => {
     const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + this.token,
     });

     this.http.get<ServerMessage>(
       this.baseURL + 'administrator/get-organization-admin-choices/' + choice, {
         headers: headers
       }).subscribe((response: ServerMessage) => {
       resolve(response);
     }, (error) => {
       reject(error)
     });
   })
 }


 /**
  * @TODO cambiar al controlador de admin/organization
  */
   async getOrganizationAdmin(idOrganization: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });
 
      this.http.get<ServerMessage>(
        this.baseURL + 'administrator/get-organization-admin/' + idOrganization, {
          headers: headers
        }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  /**
   * @TODO cambiar al controlador de admin/organization
   */
   async createOrganization(organizationData: FormData): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(
        this.baseURL + 'administrator/create-organization', organizationData, {
          headers: headers
        }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async uploadLogoFileOrganization(fileData: FormData): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post(this.baseURL + 'logo-images-uploads/add-logo-image-file', fileData, { headers: headers })
        .subscribe(( res: any ) => {
          if (res == null || res == undefined) {
            reject(res);
          } else if (res.error == null || res.error == undefined || res.error == false) {
            resolve(res);
          } else {
            resolve(res);
          }
        }, (error) => {
          reject(error);
        });
    });
  }


  async downloadLogo(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseURL + url, {responseType: 'blob'})
      .subscribe(
        (response: any) => {
        if (response == null || response == undefined)
          reject(response);
        else {
          resolve(response);
        }
      }, (error: any) => {
      });
    }); 
  }


 /**
  * @TODO cambiar al controlador de admin/organization
  */
   async deleteOrganization(idOrganization: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });
 
      this.http.get<ServerMessage>(
        this.baseURL + 'administrator/delete-organization/' + idOrganization, {
          headers: headers
        }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }


 /**
  * Obtener todas las organizaciones contenidas en la base de datos
  * @returns todas las organizaciones contenidas en la base de datos excepto la principal 
  */
  async getAllOrganizations(): Promise<ServerMessage> {
   return new Promise((resolve, reject) => {
     const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + this.token,
     });

     this.http.get<ServerMessage>(
       this.baseURL + 'production/departure-orders/get-all-organizations', {
         headers: headers
       }).subscribe((response: ServerMessage) => {
       resolve(response);
     }, (error) => {
       reject(error)
     });
   })
 }


 /**
  * 
  * @returns 
  */
  async getDevicesInStock(): Promise<ServerMessage> {
   return new Promise((resolve, reject) => {
     const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + this.token,
     });

     this.http.get<ServerMessage>(
       this.baseURL + 'production/departure-orders/get-devs-in-stock', {
         headers: headers
       }).subscribe((response: ServerMessage) => {
       resolve(response);
     }, (error) => {
       reject(error)
     });
   })
 }


 /**
  * 
  * @returns 
  */
  async getDevicesOnDemmand(): Promise<ServerMessage> {
   return new Promise((resolve, reject) => {
     const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + this.token,
     });

     this.http.get<ServerMessage>(
       this.baseURL + 'production/departure-orders/get-devs-on-demmand', {
         headers: headers
       }).subscribe((response: ServerMessage) => {
       resolve(response);
     }, (error) => {
       reject(error)
     });
   })
 }


 /**
  * @TODO cambiar al controlador de admin/organization
  */
  async createDepartureOrder(departureOrderData: any): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(
        this.baseURL + 'production/departure-orders/create-order', departureOrderData, {
          headers: headers
        }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  /**
   * 
   * @param idDepartureOrder 
   * @returns 
   */
  async cancelDepartureOrder(idDepartureOrder: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });
 
      this.http.get<ServerMessage>(
        this.baseURL + 'production/departure-orders/cancel-order/' + idDepartureOrder, {
          headers: headers
        }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  /**
   * 
   * @param idDepartureOrder 
   * @returns 
   */
  async completeDepartureOrder(idDepartureOrder: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });
      this.http.get<ServerMessage>(
        this.baseURL + 'production/departure-orders/complete-order/' + idDepartureOrder, {
          headers: headers
        }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

 /**
  * 
  * @returns 
  */
  async getAllDepartureOrders(): Promise<ServerMessage> {
   return new Promise((resolve, reject) => {
     const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + this.token,
     });

     this.http.get<ServerMessage>(
       this.baseURL + 'production/departure-orders/get-all-orders', {
         headers: headers
       }).subscribe((response: ServerMessage) => {
       resolve(response);
     }, (error) => {
       reject(error)
     });
   })
  }

 /**
  * 
  * @returns 
  */
  async changePassword(email: string, pswrd: string, token: string): Promise<ServerMessage> {
    const credentials = {
      email: email,
      password: pswrd,
    };

    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      });

     this.http.post<ServerMessage>(
       this.baseURL + 'user/change-password', credentials, {
         headers: headers
       }).subscribe((response: ServerMessage) => {
       resolve(response);
     }, (error) => {
       reject(error)
     });
   })
  }

  async getFieldDevices(): Promise<FieldDevices[]>{
    return new Promise<FieldDevices[]>((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });
     this.http.get(
       this.baseURL + 'fieldtest/', {
         headers: headers
       }).subscribe((response: FieldDevices[]) => {
        resolve(response);
     }, (error) => {
        reject(error)
     });
   })
  }

  async addDeviceToFieldDevices(serialNumbers: string[]) {
    const requestBody = {
      serialNumbers: serialNumbers
    };
  
    return new Promise<ServerMessage>((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });
  
      this.http.post(
        this.baseURL + 'fieldtest/',
        requestBody, // Pasamos el objeto con la propiedad "serialNumbers" en el cuerpo del POST
        {
          headers: headers
        }
      ).subscribe(
        (response: ServerMessage) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async removeDeviceToFieldDevices(serialNumbers: string[]): Promise<ServerMessage[]>{
  
    const params = serialNumbers.join(',');
  
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token,
    });
  
    return this.http.delete<ServerMessage[]>(
      this.baseURL + `fieldtest/${params}`,
      { headers: headers}
    ).toPromise();
  }  


  /*
      *****    ******    ******    ******    *******  ****   **
      **  **   **        **          **      **   **  ** **  **
      *******  ****      *******     **      **   **  **  ** **
      **   **  **        **   **     **      **   **  **   ****
      **   **  *******   *******   ******    *******  **    ***
  */
  async createRegion(regionData: any): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(
        this.baseURL + 'organization/organization-data/create-region', regionData, {
          headers: headers
        }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  async updateRegion(regionData: any,idRegion: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.patch<ServerMessage>(
        this.baseURL + 'organization/organization-data/edit-region/' + idRegion, regionData, {
          headers: headers
        }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async deleteRegionService(idRegion: number): Promise<ServerMessage> {
    //console.log(idRegion)
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });
 
      this.http.delete<ServerMessage>(
        this.baseURL + 'organization/organization-data/delete-region/' + idRegion, {
          headers: headers
        }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }




/**
        ******    ********   **     **   ********                         
          **      **    **   ** **  **   **    ** 
         **       **    **   **  ** **   ********
        **        **    **   **   ****   **    **
       *******    ********   **    ***   **    **                  
 */

  async createZone(stationData: any): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });
      this.http.post<ServerMessage>(
        this.baseURL + 'organization/organization-data/create-zone/', stationData, {
          headers: headers
        }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async updateZone(idZone:number ,stationData: any): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });
      this.http.patch<ServerMessage>(
        this.baseURL + 'organization/organization-data/edit-zone/' + idZone, stationData, {
          headers: headers
        }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async deleteZone(idZone: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });
 
      this.http.delete<ServerMessage>(
        this.baseURL + 'organization/organization-data/delete-zone/' + idZone, {
          headers: headers
        }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

/*
     *****  ******   *******  ********  ********   *******   **    **
     **       **     **   **     **        **      **   **   ****  **
     *****    **     *******     **        **      **   **   ** ** **
        **    **     **   **     **        **      **   **   **  ****
     *****    **     **   **     **     ********   *******   **   ***
*/ 
  //  -CRUD station

  // Obtiene las estaciones de la organización que pertenezca
  async getStation(idOrganization : number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });
    
      this.http.get<ServerMessage>(
        this.baseURL + 'organization/organization-data/find-stations-byOrganization/'+idOrganization ,{ headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  
  async createStation(stationData: any): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });
      this.http.post<ServerMessage>(
        this.baseURL + 'organization/organization-data/create-station/', stationData, {
          headers: headers
        }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async updateStation(idStation:number ,stationData: any): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });
      this.http.patch<ServerMessage>(
        this.baseURL + 'organization/organization-data/edit-station/' + idStation, stationData, {
          headers: headers
        }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  
  async deleteStation(idStation: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });
 
      this.http.delete<ServerMessage>(
        this.baseURL + 'organization/organization-data/delete-station/' + idStation, {
          headers: headers
        }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  async getDevicesByStation(idStation: number): Promise<ServerMessage>{
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/find-devices-byStation/' + idStation , { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  async assignDevice(deviceData: any,device: any): Promise<ServerMessage> {
   
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });
      this.http.post<ServerMessage>(
        this.baseURL + 'organization/organization-data/create-devices-InStation/' + device, deviceData, {
          headers: headers
        }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async deleteDeviceInStation(idDevice: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });
      this.http.delete<ServerMessage>(
        this.baseURL + 'organization/organization-data/delete-devices-InStation/' + idDevice, {
          headers: headers
        }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  //lista supervisores

  async getSupervisor(idOrganization : number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/find-supervisorList/' + idOrganization , { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  //obtener zonas 
  async getZones(idOrganization : number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/find-zones-organization/' + idOrganization , { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

/*
  async updateUserAdmin(newUserData: User): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/update-user', newUserData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async deleteUserAdmin(idUser: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'administrator/delete-user/' + idUser, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
*/

}


  // getGasDeviceAlerts(
  //   idDevice: number,
  //   period: number
  // ): Promise<ServerMessage> {
  //   return new Promise((resolve, reject) => {  
  //     const headers = new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + this.token
  //     })

  //     this.http.get<ServerMessage>(
  //       this.baseURL + 'administrator/get-gas-device-alerts/' + idDevice + '/' + period,
  //       { headers: headers }).subscribe((response: ServerMessage) => {
  //         resolve(response);
  //       }, (error) => {
  //         reject(error)
  //       });
  //   })
  // }


  //TO DO : Implementacion Servidor
  /*   async uploadImageUser(formData: FormData) {
      return new Promise((resolve,reject)=>{
        const headers = new HttpHeaders({
          'Authorization': 'Bearer '+this.token,
        });
  
        this.http.post<ServerMessage>(this.baseURL + 'uploads/user-image/', formData, {headers:headers })
          .subscribe((res: ServerMessage) => {
            if (res.error == false) {
              resolve(res);
            } else if( res.error == undefined){
              console.log("error no llego nada");
              reject(res);
            }else{
              resolve(res);
            }
          },(error)=>{
            reject(error);
          },);
      });
    } */

  //TO DO : Implementacion Servidor
  /*   deleteImageUser(idUser){
      return new Promise((resolve,reject)=>{
        const headers = new HttpHeaders({
          'Authorization': 'Bearer '+this.token,
        });
        this.http.get<ServerMessage>(this.baseURL + 'uploads/user-delete-image/'+idUser,{headers:headers}).subscribe((response : ServerMessage)=>{
          resolve(response);
        },(error)=>{
          reject(new ServerMessage(true,"A ocurrido un error inesperado",error));
        });
      });
    } */

  //EJEMPLO DE USO DEL METODO GET 
  /* async getRespuestas( entidad : string ) {
    return new Promise(async (resolve,reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.user.token
      })
       
      this.http.get<ServerMessage>(this.baseURL + 'user/obtener-respuestas',{ headers: headers }).subscribe((response : ServerMessage)=>{
        resolve(response);
      },(error)=>{
        reject(error)
      });
    });
  } */

  //EJEMPLO DE USO DEL METODO POST 
  /* async saveRespuestas(respuestas : any){
    return new Promise((resolve,reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.user.token
      });

      this.http.post<ServerMessage>(this.baseURL + 'user/save-respuestas',respuestas,{ headers: headers }).subscribe((response : ServerMessage)=>{
        resolve(response);
      },(error)=>{
        reject(error)
      });
    })
  } */
